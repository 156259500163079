import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import { lighten, darken } from 'polished';
import { useTranslation } from 'react-i18next';

import { defaultTheme } from 'styles/theme';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchCart } from 'redux/slices/storeSlice';

import { Modal, ModalTitle, ModalText, ModalIcon, ModalError } from 'components/modals/Modal';
import { Input } from 'components/FormElements';
import { Button } from 'components/Buttons';
import { Icon } from 'components/Icon';

import { getActiveCouponDeal } from 'lib/helpers';
import { api } from 'lib/api';

interface AddCouponLinkProps {
    cartId: string | undefined;
    brandingColor: null | string;
    showId?: string;
    emptyCart?: boolean;
}

export const AddCouponLink: React.FC<AddCouponLinkProps> = (props) => {
    const { cartId, brandingColor, showId, emptyCart } = props;

    const { edition } = useAppSelector((state) => {
        return {
            edition: state.store.edition,
        };
    });

    const [searchParams] = useSearchParams();

    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (searchParams.get('voucher')) {
            setShowModal(true);
        }
    }, []);

    if (!cartId || !edition?.promotions) return null;

    return (
        <>
            {!emptyCart && (
                <StyledCouponButton onClick={() => setShowModal(true)}>
                    <Icon name="coupon-line" />
                    {t('modal.coupon.add_coupon')}
                </StyledCouponButton>
            )}
            {emptyCart && (
                <StyledCouponLink onClick={() => setShowModal(true)}>
                    <Icon name="coupon-line" />
                    {t('modal.coupon.add_coupon')}
                </StyledCouponLink>
            )}
            <CouponModal
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                cartId={cartId}
                brandingColor={brandingColor}
                showId={showId}
            />
        </>
    );
};

const StyledCouponButton = styled.button`
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: 700;
    font-size: 1rem;
    outline: none;
    margin-bottom: 1.5rem;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
    }

    svg {
        display: inline-block;
        margin-right: 0.25rem;
        position: relative;
        bottom: -0.125rem;
    }
`;

const StyledCouponLink = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    cursor: pointer;

    svg {
        display: inline-block;
        margin-right: 0.25rem;
        position: relative;
        bottom: -0.125rem;
    }
`;

interface CouponModalProps {
    showModal: boolean;
    cartId: string;
    closeModal: () => void;
    brandingColor: null | string;
    showId?: string;
}

const CouponModal: React.FC<CouponModalProps> = (props) => {
    const { showModal, closeModal, cartId, brandingColor, showId } = props;
    const { cart, cartIsLoading } = useAppSelector((state) => {
        return {
            cart: state.store.cart,
            cartIsLoading: state.store.cartIsLoading,
        };
    });

    const { lang } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const confettiColor = brandingColor ?? defaultTheme.colors.primary;
    const isOnMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [code, setCode] = useState<string>('');
    const [validationError, setValidationError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showConfetti, setShowConfetti] = useState<boolean>(false);
    const [successfulCode, setSuccessfulCode] = useState<null | 'voucher' | 'coupon'>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const voucherInSearchParams = searchParams.get('voucher');
        if (voucherInSearchParams) {
            setCode(voucherInSearchParams.toUpperCase());
            submitCode(voucherInSearchParams.toUpperCase());
        }
    }, []);

    const clearAndClose = () => {
        setCode('');
        closeModal();
        setValidationError(undefined);
        setSuccessfulCode(null);
        searchParams.delete('voucher');
        setSearchParams(searchParams);
    };

    const clearForNewCode = () => {
        setCode('');
        setValidationError(undefined);
        setSuccessfulCode(null);
    };

    const validateCode = async (url: string, data: { cart: string; code: string }) => {
        try {
            const response = await api.post(url, data);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return error.response?.data;
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent) => {
        e.preventDefault();
        submitCode(code);
    };

    const submitCode = async (submittedCode: string) => {
        setShowConfetti(false);
        setValidationError(undefined);
        setIsLoading(true);

        const data: { cart: string; code: string; show?: string } = {
            cart: cartId,
            code: submittedCode,
        };

        if (showId) {
            data['show'] = showId;
        }

        let coupon;
        const voucher = await validateCode('/voucher', data);

        if (voucher.error) {
            switch (voucher.error) {
                // Voucher does not exist in the entire database - try if coupon
                case 'validation_failed':
                // Voucher does not exist in the current edition - try if coupon
                case 'voucher_not_found':
                    // Try coupon
                    coupon = await validateCode('/coupon', data);
                    break;
                // Voucher is not valid anymore (batch expired) - show error
                case 'voucher_expired':
                // Voucher batch is not active - show error
                case 'voucher_not_active':
                // Voucher can not be used in this show (when show is provided) - show error
                case 'voucher_invalid_show':
                // Voucher is already redeemed and / or reserved - show error
                case 'voucher_not_available':
                // Voucher is found but the type is out of stock - show error
                case 'out_of_stock':
                    // Show error
                    setValidationError(voucher.error);
                    break;
            }
        }

        if (coupon?.error) {
            // If coupon is not valid show errors based on voucher error
            if (voucher.error === 'voucher_not_found') {
                setValidationError('voucher_not_found');
            } else if (voucher.error === 'validation_failed') {
                const [key, value] = Object.entries(voucher.validation)[0];
                setValidationError(`validation_${key}_${value}`);
            } else {
                setValidationError(`default`);
            }
        }

        if (coupon?.success || voucher?.success) {
            dispatch(fetchCart(lang));
            setShowConfetti(true);
            if (coupon?.success) setSuccessfulCode('coupon');
            if (voucher?.success) setSuccessfulCode('voucher');

            searchParams.delete('voucher');
            setSearchParams(searchParams);
        }

        setIsLoading(false);
    };

    const confettiConfig = {
        angle: 90,
        spread: 250,
        startVelocity: 50,
        width: '10px',
        height: '10px',
        elementCount: 170,
        dragFriction: 0.07,
        duration: 3500,
        stagger: 3,
        colors: [
            lighten(0.2, confettiColor),
            lighten(0.1, confettiColor),
            confettiColor,
            darken(0.1, confettiColor),
            darken(0.2, confettiColor),
        ],
    };

    const isVisible = !!showModal;

    return (
        <Modal closeModal={clearAndClose} loading={isLoading || cartIsLoading} center isVisible={isVisible}>
            <ModalIcon name="coupon-line" />
            <StyledConfetti>
                <Confetti active={showConfetti && !cartIsLoading} config={confettiConfig} />
            </StyledConfetti>
            {(!successfulCode || cartIsLoading) && (
                <>
                    <ModalTitle>{t('modal.coupon.form_title')}</ModalTitle>
                    <ModalText>
                        {t('modal.coupon.form_lead_1')}
                        <br />
                        {t('modal.coupon.form_lead_2')}
                    </ModalText>

                    {validationError && (
                        <ModalError onClick={() => setValidationError(undefined)}>
                            {t(`modal.coupon.error.${validationError}`)}
                        </ModalError>
                    )}
                    <form onSubmit={handleSubmit}>
                        <StyledInput
                            name="code"
                            value={code}
                            onChange={(e) => setCode(e.target.value.toUpperCase())}
                            placeholder={t('modal.coupon.form_placeholder')}
                            autoFocus={!isOnMobile}
                        />
                        <StyledCouponActionButtons>
                            <Button type="submit" fullWidth>
                                {t('modal.coupon.form_submit')}
                            </Button>
                            <Button type="button" buttonType="text" fullWidth onClick={clearAndClose}>
                                {t('modal.coupon.form_cancel')}
                            </Button>
                        </StyledCouponActionButtons>
                    </form>
                </>
            )}
            {successfulCode && !cartIsLoading && (
                <>
                    {successfulCode === 'voucher' && (
                        <>
                            <ModalTitle>{t('modal.coupon.voucher_success_title')}</ModalTitle>
                            <ModalText>{t('modal.coupon.voucher_success_lead')}</ModalText>
                            <StyledCouponActionButtons>
                                <Button fullWidth onClick={clearAndClose}>
                                    {t('modal.coupon.voucher_success_to_shop')}
                                </Button>
                                <Button type="button" buttonType="text" fullWidth onClick={clearForNewCode}>
                                    {t('modal.coupon.voucher_success_new_code')}
                                </Button>
                            </StyledCouponActionButtons>
                        </>
                    )}
                    {successfulCode === 'coupon' && (
                        <>
                            <ModalTitle>{getActiveCouponDeal(cart)?.name}</ModalTitle>
                            <ModalText>{getActiveCouponDeal(cart)?.description}</ModalText>
                            <StyledCouponActionButtons>
                                <Button fullWidth onClick={clearAndClose}>
                                    {t('modal.coupon.coupon_success_to_shop')}
                                </Button>
                            </StyledCouponActionButtons>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

const StyledConfetti = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledInput = styled(Input)`
    margin-bottom: 1rem;
`;

const StyledCouponActionButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
`;
