import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchEvent, setEdition } from 'redux/slices/storeSlice';

import { EditionsList } from 'components/EditionsList';
import { NotFound } from 'components/NotFound';

import { useGA } from 'hooks/useGA';
import { devLog } from 'lib/dev';
import { Loader } from 'components/LoadingSpinner';
import { FullScreenError } from 'components/modals/FullScreenError';

export const Event = () => {
    // Load Event but if lang !== :lang replace url /defaultLang/...
    // Or if :lang is element of editionsArray replace /defaultLang/langAsEditionUrl
    const { event, eventError, edition } = useAppSelector((state) => {
        return { event: state.store.event, eventError: state.store.eventError, edition: state.store.edition };
    });
    const dispatch = useAppDispatch();
    const { lang, editionUri } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    useGA(event, edition);

    const hasValidLanguage = (): boolean => {
        if (!event || !lang) return false;
        const { languages } = event;
        return languages.includes(lang);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const previewToken = searchParams.get('preview_token') ?? undefined;
        dispatch(fetchEvent(lang, previewToken));
        setTimeout(() => {
            setLoading(true);
        }, 1000);
    }, []);

    // Clear edition when coming back to edition list
    useEffect(() => {
        if (editionUri) return;
        dispatch(setEdition(undefined));
    }, [editionUri]);

    useEffect(() => {
        if (!event || !lang) return;
        const { languages, editions } = event;
        const { pathname, search } = location;

        const i18nLanguage = i18n.language;
        setLoading(false);

        // if lang parameter in url is longer than 2 characters,
        // or if this parameter is not included in supported languages
        // redirect to default language of event
        if (lang.length > 2) {
            const navigatorLanguage = navigator.language.slice(0, 2);
            const newLanguage = languages.includes(i18nLanguage)
                ? i18nLanguage
                : languages.includes(navigatorLanguage)
                ? navigatorLanguage
                : languages[0];
            navigate(`/${newLanguage}${pathname + search}`, { replace: true });
            return;
        }

        if (!hasValidLanguage()) {
            const navigatorLanguage = navigator.language.slice(0, 2);
            const newLanguage = languages.includes(i18nLanguage)
                ? i18nLanguage
                : languages.includes(navigatorLanguage)
                ? navigatorLanguage
                : languages[0];
            const newPathname = pathname.replace(`/${lang}`, `/${newLanguage}`);
            devLog('wrong language', lang);
            navigate(`${newPathname + search}`, { replace: true });
            return;
        }

        i18n.changeLanguage(lang);

        if (editions.length === 1 && !editionUri) {
            // if event has only 1 active edition redirect to this edition
            navigate(`/${lang}/${editions[0].uri}${search}`, { replace: true });
            return;
        }
    }, [event, lang, editionUri]);

    if (eventError?.error === 'invalid_uri') {
        return (
            <>
                <Helmet>
                    <title>{t('event.store_not_found')} | EventSquare</title>
                </Helmet>
                <NotFound />
            </>
        );
    }

    if (!event || !hasValidLanguage()) {
        return (
            <>
                <StyledLoadingPage>{loading && <Loader />}</StyledLoadingPage>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`${event.name} | EventSquare`}</title>
            </Helmet>

            {event && (
                <>
                    <Outlet />
                    {/* <ChangeEditionModal event={event} newEditionUri={undefined} unsetNewEditionUri={undefined} /> */}
                </>
            )}
            {event?.editions && !event.editions.length && !editionUri && (
                <FullScreenError
                    editionError={{ code: 403, error: 'channel_closed', message: 'Channel is closed.' }}
                    event={event}
                />
            )}
            {event?.editions && event.editions.length > 1 && !editionUri && <EditionsList lang={lang} />}
            {eventError && <div>{eventError.message}</div>}
        </>
    );
};

const StyledLoadingPage = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: ${(props) => props.theme.colors.primary};
`;
